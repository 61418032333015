import { message } from "antd";
import axios from "axios";

const InternalDomainApis = {
  create: async (body) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}internal/create`,
        body,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenDrm"),
            email: localStorage.getItem("emailDrm"),
            iddrm: localStorage.getItem("idDrm"),
          },
        }
      );

      if (response.data.status) {
        console.log("response", response);
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log("Error during client creation", error);
      console.error("Error during login", error);
      return null;
    }
  },

  findAll: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}internal/findAll`,
        {
         
          headers: {
            "x-access-token": localStorage.getItem("accessTokenDrm"),
            email: localStorage.getItem("emailDrm"),
            iddrm: localStorage.getItem("idDrm"),
          },
        }
      );

      if (response.data.status) {
        console.log("response", response);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },

  findOne: async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}internal/findOne/${id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenDrm"),
            email: localStorage.getItem("emailDrm"),
            iddrm: localStorage.getItem("idDrm"),
          },
        }
      );

      if (response.data.status) {
        console.log("response", response);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },

  update: async (id, body) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}internal/update/${id}`,
        body,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenDrm"),
            email: localStorage.getItem("emailDrm"),
            iddrm: localStorage.getItem("idDrm"),
          },
        }
      );

      if (response.data.status) {
        console.log("response", response);
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
  delete: async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}internal/delete/${id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenDrm"),
            email: localStorage.getItem("emailDrm"),
            iddrm: localStorage.getItem("idDrm"),
          },
        }
      );

      if (response.data.status) {
        console.log("response", response);
        return response.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      return null;
    }
  },
 

  getExpiredInternalDomain: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}internal/findDomainsByDate`,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenDrm"),
            email: localStorage.getItem("emailDrm"),
            iddrm: localStorage.getItem("idDrm"),
          },
        }
      );

      if (response) {
        console.log("response", response);
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Data Not Found");
      return null;
    }
  },
};

export default InternalDomainApis;
