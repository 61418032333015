import { message } from "antd";
import axios from "axios";

const LoginVerify = {
  signin: async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}admin/login`,
        { email, password }
      );

      if (response.data.status) {
        console.log("response", response);
        const accessToken = response.data.data.token;
        const email = response.data.data.email;
        const name = response.data.data.name;
        const id = response.data.data.id;

        console.log("accessToken", accessToken);

        localStorage.setItem("accessTokenDrm", accessToken);
        localStorage.setItem("emailDrm", email);
        localStorage.setItem("nameDrm", name);
        localStorage.setItem("idDrm", id);

        if (response.data.message === "Successfully login") {
          message.success("Successfully logged in.");
        }
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during login", error);
      alert("Error during login");
      return null;
    }
  },
};

export default LoginVerify;
