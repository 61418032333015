export default function TableHead({ children, position = "center", centered = true,
    width = " 140px",
    height = "60px",
}) {
    return (
        <div
            style={{
                width: width,
                justifyContent: position,
                height: height,
            }}
            className={`flex items-center ${centered ? "text-center" : "text-left"} pl-[11px]  text-[14px]`}>
            {children}
        </div>
    );
}
