import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import BookingTable from "./booking-tables/BookingTable";

function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${
        isActive ? "text-primary-blue" : "text-placeholder-gray"
      } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

function BookingTabNavigation({
  setIsOpen,
  setIsEditOpen,
  setIsViewOpen,
  activeKey,
  setActiveKey,
  clientList,
  setSearch,
  setSearchHostingBy,
  setSearchDomainBy,
  searchDomainBy,
  searchHostingBy,
  search,
  setClientId,
  handleSendEmail,
  handleSendWhatsApp,
  handleRequestPayment,
}) {
  const onChange = (key) => {
    setActiveKey(key);
  };

  const items = [
    {
      key: 1,
      label: <LabelForTabs isActive={activeKey === 1}>All Domain</LabelForTabs>,
      children: (
        <BookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          clientList={clientList}
          setSearch={setSearch}
          setSearchHostingBy={setSearchHostingBy}
          setSearchDomainBy={setSearchDomainBy}
          searchDomainBy={searchDomainBy}
          searchHostingBy={searchHostingBy}
          search={search}
          setClientId={setClientId}
          handleSendEmail={handleSendEmail}
          handleSendWhatsApp={handleSendWhatsApp}
          handleRequestPayment={handleRequestPayment}
        />
      ),
    },
    {
      key: 2,
      label: <LabelForTabs isActive={activeKey === 2}>Live</LabelForTabs>,
      children: (
        <BookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          clientList={clientList}
          setSearch={setSearch}
          setSearchHostingBy={setSearchHostingBy}
          setSearchDomainBy={setSearchDomainBy}
          searchDomainBy={searchDomainBy}
          searchHostingBy={searchHostingBy}
          search={search}
          setClientId={setClientId}
          handleSendEmail={handleSendEmail}
          handleSendWhatsApp={handleSendWhatsApp}
          handleRequestPayment={handleRequestPayment}
        />
      ),
    },
    {
      key: 3,
      label: (
        <LabelForTabs isActive={activeKey === 3}>Domain Expired</LabelForTabs>
      ),
      children: (
        <BookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          clientList={clientList}
          setSearch={setSearch}
          setSearchHostingBy={setSearchHostingBy}
          setSearchDomainBy={setSearchDomainBy}
          searchDomainBy={searchDomainBy}
          searchHostingBy={searchHostingBy}
          search={search}
          setClientId={setClientId}
          handleSendEmail={handleSendEmail}
          handleSendWhatsApp={handleSendWhatsApp}
          handleRequestPayment={handleRequestPayment}
        />
      ),
    },
    {
      key: 4,
      label: (
        <LabelForTabs isActive={activeKey === 4}>
          Domain Expired CM
        </LabelForTabs>
      ),
      children: (
        <BookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          clientList={clientList}
          setSearch={setSearch}
          setSearchHostingBy={setSearchHostingBy}
          setSearchDomainBy={setSearchDomainBy}
          searchDomainBy={searchDomainBy}
          searchHostingBy={searchHostingBy}
          search={search}
          setClientId={setClientId}
          handleSendEmail={handleSendEmail}
          handleSendWhatsApp={handleSendWhatsApp}
          handleRequestPayment={handleRequestPayment}
        />
      ),
    },
    {
      key: 5,
      label: (
        <LabelForTabs isActive={activeKey === 5}>
          Domain Expired NM
        </LabelForTabs>
      ),
      children: (
        <BookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          clientList={clientList}
          setSearch={setSearch}
          setSearchHostingBy={setSearchHostingBy}
          setSearchDomainBy={setSearchDomainBy}
          searchDomainBy={searchDomainBy}
          searchHostingBy={searchHostingBy}
          search={search}
          setClientId={setClientId}
          handleSendEmail={handleSendEmail}
          handleSendWhatsApp={handleSendWhatsApp}
          handleRequestPayment={handleRequestPayment}
        />
      ),
    },
    {
      key: 6,
      label: (
        <LabelForTabs isActive={activeKey === 6}>Hosting Expired</LabelForTabs>
      ),
      children: (
        <BookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          clientList={clientList}
          setSearch={setSearch}
          setSearchHostingBy={setSearchHostingBy}
          setSearchDomainBy={setSearchDomainBy}
          searchDomainBy={searchDomainBy}
          searchHostingBy={searchHostingBy}
          search={search}
          setClientId={setClientId}
          handleSendEmail={handleSendEmail}
          handleSendWhatsApp={handleSendWhatsApp}
          handleRequestPayment={handleRequestPayment}
        />
      ),
    },
    {
      key: 7,
      label: (
        <LabelForTabs isActive={activeKey === 7}>
          Hosting Expired CM
        </LabelForTabs>
      ),
      children: (
        <BookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          clientList={clientList}
          setSearch={setSearch}
          setSearchHostingBy={setSearchHostingBy}
          setSearchDomainBy={setSearchDomainBy}
          searchDomainBy={searchDomainBy}
          searchHostingBy={searchHostingBy}
          search={search}
          setClientId={setClientId}
          handleSendEmail={handleSendEmail}
          handleSendWhatsApp={handleSendWhatsApp}
          handleRequestPayment={handleRequestPayment}
        />
      ),
    },
    {
      key: 8,
      label: (
        <LabelForTabs isActive={activeKey === 8}>
          Hosting Expired NM
        </LabelForTabs>
      ),
      children: (
        <BookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          clientList={clientList}
          setSearch={setSearch}
          setSearchHostingBy={setSearchHostingBy}
          setSearchDomainBy={setSearchDomainBy}
          searchDomainBy={searchDomainBy}
          searchHostingBy={searchHostingBy}
          search={search}
          setClientId={setClientId}
          handleSendEmail={handleSendEmail}
          handleSendWhatsApp={handleSendWhatsApp}
          handleRequestPayment={handleRequestPayment}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs
        activeKey={activeKey}
        items={items}
        onChange={onChange}
        className="!border-t-red-500"
      />
    </div>
  );
}

export default BookingTabNavigation;
