import { message } from "antd";
import axios from "axios";

const DashBoardApi = {
  getDashboardData: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}dashboard/getDashboardData`,
        {
          headers: {
            "x-access-token": localStorage.getItem("accessTokenDrm"),
            email: localStorage.getItem("emailDrm"),
            iddrm: localStorage.getItem("idDrm"),
          },
        }
      );

      if (response.data.status) {
        console.log("response", response);
        message.success(response.data.message);
        return response.data.data;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log("Error during dashboard creation", error);
      console.error("Error during login", error);
      return null;
    }
  },
};

export default DashBoardApi;
