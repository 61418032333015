import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, message } from "antd";
import TableHead from "../../components/resuable/TableHead";
import TableCell from "../../components/resuable/TableCell";
import Table from "../../components/resuable/Table";
import { IoFilterSharp } from "react-icons/io5";
import DateUtils from "../../services/utils/dateUtils";
import { JsonToExcel } from "react-json-to-excel";

export default function InternalBookingTable({
  setIsOpen,
  setIsEditOpen,
  setIsViewOpen,
  internalDomainList,
  setInternalDomainId,
  setSearchModal
}) {
  const columns = [
    {
      id: "domainName",
      name: <TableHead>Domain Name</TableHead>,
      cell: (row) => <TableCell>{row.domainName}</TableCell>,
    },
    {
      id: "expiryDomainDate",
      name: <TableHead>Domain Expiry</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.expiryDomainDate
            ? DateUtils.getExDateFormat(row.domainExpiryDate)
            : "-"}
        </TableCell>
      ),
    },
    {
      id: "providedDomainBy",
      name: <TableHead> Domain Provider</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.domainProvider}
        </TableCell>
      ),
    },
   
   
    ...(localStorage.getItem("idDrm") === "1"
      ? [{
          id: "action",
          name: <TableHead>Action</TableHead>,
          cell: (row) => (
            <TableCell>
              <div className="flex gap-5">
             
                <i
                  className="fas fa-edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsOpen(true);
                    setIsEditOpen(true);
                    setInternalDomainId(row.id);
                    setSearchModal(true)
                  }}
                  title="Edit"
                ></i>
                <i
                  className="fas fa-eye"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsOpen(true);
                    setIsViewOpen(true);
                    setInternalDomainId(row.id);
                    setSearchModal(true)
                  }}
                  title="View"
                ></i>
              
              </div>
            </TableCell>
          ),
        }]
      : []),
  ];
  
  

  return (
    <div>

      <div className="card-body">
        <Table columns={columns} rows={internalDomainList} />
      </div>
    </div>
  );
}
