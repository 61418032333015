import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import InternalBookingTable from "./booking-tables/InternalBookingTable";

function LabelForTabs({ children, isActive }) {
  return (
    <div  
      className={`${
        isActive ? "text-primary-blue" : "text-placeholder-gray"
      } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

function InternalTabNavigation({
  setIsOpen,
  setIsEditOpen,
  setIsViewOpen,
  activeKey,
  setActiveKey,
  setInternalDomainId,
  internalDomainList,
  setSearchModal
}) {
  const onChange = (key) => {
    setActiveKey(key);
  };

  const items = [
    {
      key: 1,
      label: <LabelForTabs isActive={activeKey === 1}>All Domain</LabelForTabs>,
      children: (
        <InternalBookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          internalDomainList={internalDomainList}
          setInternalDomainId={setInternalDomainId}
          setSearchModal={setSearchModal}
        />
      ),
    },
    
    {
      key: 2,
      label: (
        <LabelForTabs isActive={activeKey === 2}>Domain Expired</LabelForTabs>
      ),
      children: (
        <InternalBookingTable
          setIsOpen={setIsOpen}
          setIsEditOpen={setIsEditOpen}
          setIsViewOpen={setIsViewOpen}
          internalDomainList={internalDomainList} 
          setInternalDomainId={setInternalDomainId}
          setSearchModal={setSearchModal}
        />
      ),
    },
  
  ];

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs
        activeKey={activeKey}
        items={items}
        onChange={onChange}
        className="!border-t-red-500"
      />
    </div>
  );
}

export default InternalTabNavigation;
