import React, { useEffect } from "react";
import Menu from "./Menu";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname;

  useEffect(() => {
    const accessToken = localStorage.getItem("accessTokenDrm");
    const idDrm = localStorage.getItem("idDrm");

    if (!accessToken) {
      navigate("/login");
    } else {
      if (url === "/") {
        if (idDrm == "1") {
          navigate("/dashboard");
        } else if (idDrm == "2") {
          navigate("/domain-entry");
        }
      }
    }
  }, []);

  return (
    <div>
      <Menu />
      <Outlet />
    </div>
  );
};

export default Layout;
