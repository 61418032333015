import { useContext, useEffect, useRef, useState } from "react";
import BookingTabNavigation from "../components/BookingTabNavigation";
import { Modal } from "antd";
import { message } from "antd";
import internalDomainApis from "../services/api/internalDomain";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InternalTabNavigation from "../components/InternalTabNavigation";

export default function InternalDomain() {
    const [domainName, setDomainName] = useState("");
    const [expiryDateFormat, setExpiryDateFormat] = useState("");
    const [providerName, setProviderName] = useState("");
 
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [internalDomainList, setIntenalDomainList] = useState([]);
  const [activeKey, setActiveKey] = useState(1);


  const [internalDomainId, setInternalDomainId] = useState("");
  
  const handleSubmit = async() => {
    if (!domainName || !expiryDateFormat || !providerName) {
      message.error("Please fill out all fields.");
      return;
    }

    const formData = {
      domainName: domainName,
      domainExpiryDate: expiryDateFormat,
      domainProvider: providerName,
    };
    const response = await internalDomainApis.create(formData);
    message.success(response.message);
    resetForm();
    if(activeKey===2){
fetchExpiredInternalDomainList()
    }
    else{
      fetchInternalDomainList()
    }
   
  };
  const resetForm = () => {
    setDomainName("");
    setExpiryDateFormat("");
    setProviderName("");
    setSearchModal(false);
  };
  const location = useLocation();
  const { id } = location.state || {}; // Access the id from location.state

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setActiveKey(id);
    } else {
      setActiveKey(1);
    }
  }, [id]);



 
  const fetchInternalDomainList = async () => {
   
    const response = await internalDomainApis.findAll();
    if (response) {
      setIntenalDomainList(response);
    }
  };
  const fetchExpiredInternalDomainList = async () => {
   
    const response = await internalDomainApis.getExpiredInternalDomain();
    if (response) {
      setIntenalDomainList(response);
    }
  };
  
  useEffect(() => {
    if(activeKey===2){    fetchExpiredInternalDomainList();}
    else{
      fetchInternalDomainList()
    }

  }, [activeKey]);



  useEffect(() => {
    if (internalDomainId) {
      const fetchInternalDomain = async () => {
        const response = await internalDomainApis.findOne(internalDomainId);
        if (response) {
          setDomainName(response.domainName);
          setExpiryDateFormat(response.domainExpiryDate);
          setProviderName(response.domainProvider);
                 }
      };
      fetchInternalDomain();
    }
  }, [internalDomainId]);



  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="flex justify-between items-center w-full px-4">
                <h1>Internal Domain</h1>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setSearchModal(true);
                    }}
                >
                  Add Domain
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="px-3">
          <InternalTabNavigation
            setIsOpen={setIsOpen}
            setIsEditOpen={setIsEditOpen}
            setIsViewOpen={setIsViewOpen}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            internalDomainList={internalDomainList}
            setInternalDomainId={setInternalDomainId}
            setSearchModal={setSearchModal}
          />
        </section>
   
        <Modal
      title="Add Domain"
      visible={searchModal}
      width="800px"
      centered
      className="relative !z-[1039]"
      onCancel={resetForm}
      footer={null}
    >
      <div className="flex flex-col space-y-4">
        <div className="form-group">
          <label htmlFor="domainName" className="block mb-1">
            Domain Name
          </label>
          <input
            type="text"
            className="form-control w-full px-3 py-2 border rounded"
            id="domainName"
            placeholder="Enter domain name"
            value={domainName}
            onChange={(e) => setDomainName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="expiryDate" className="block mb-1">
            Expiry Date
          </label>
          <input
            type="date"
            className="form-control w-full px-3 py-2 border rounded"
            id="expiryDate"
            placeholder="Enter Expiry Date"
            value={expiryDateFormat}
            onChange={(e) => setExpiryDateFormat(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="provider" className="block mb-1">
            Provider
          </label>
          <input
            type="text"
            className="form-control w-full px-3 py-2 border rounded"
            id="provider"
            placeholder="Enter Provider"
            value={providerName}
            onChange={(e) => setProviderName(e.target.value)}
          />
        </div>
        <div className="gap-4">
          <button
            type="button"
            className="btn btn-primary w-full py-2 mt-4"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
      
      </div>
    </>
  );
}
