import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/Egniol_web_Logo-2.webp";
import { message } from "antd";
import LoginVerify from "../services/api/loginApi";
export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("accessTokenDrm");

    const idDrm = localStorage.getItem("idDrm");

    if (idDrm == "1") {
      navigate("/dashboard");
    } else if (idDrm == "2") {
      navigate("/domain-entry");
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      message.error("Password must be at least 6 characters");
      return;
    }
    const response = await LoginVerify.signin(email, password);
    if (response.error) {
      message.error(response.error);
      return;
    }
    if (response) {
      navigate("/dashboard");
    }
  };

  return (
    <div className="h-screen flex justify-center items-center bg-[#233240]">
      <div class="login-box lg:w-[400px]">
        <div class="card card-outline card-primary">
          <div class="card-header text-center">
            <a href="/" class="h1">
              <img src={logo} alt="" />
            </a>
          </div>
          <form class="card-body" onSubmit={handleLogin}>
            <p class="login-box-msg">DOMAIN RENEWAL MANAGEMENT</p>
            <div class="input-group mb-6">
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-6">
              <input
                type="password"
                class="form-control"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary btn-block text-white font-medium mt-6"
            >
              Sign In
            </button>
            <div class="row pt-2 px-2 mt-4">
              <div class="flex justify-end w-full items-center"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
