import { useContext, useEffect, useRef, useState } from "react";
import DashBoardApi from "../services/api/dashBoardApi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState({
    totalDomainByEgniol: 0,
    totalHostingByEgniol: 0,
    totalRunningDomainByEgniol: 0,
    totalExpiredDomainByEgniol: 0,
    totalRunningHostingByEgniol: 0,
    totalExpiredHostingByEgniol: 0,
  });

  const navigate = useNavigate();

  const fetchData = async () => {
    const data = await DashBoardApi.getDashboardData();
    if (data) {
      setDashboardData(data);
    }
  };

  useEffect(() => {
    const idDrm = localStorage.getItem("idDrm");
    if (idDrm == "1") {
      navigate("/dashboard");
      setTimeout(() => {
        fetchData();
      }, 2000);
    } else if (idDrm == "2") {
      navigate("/domain-entry");
    }
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>
        {/* Main content */}
        <section className="content justify-center items-center">
          <div className="container-fluid ">
            {/* Small boxes (Stat box) */}
            <div className="row">
              <div className="col-lg-3 col-6">
                {/* small box */}
                <Link
                  to="/client-management"
                  state={{ id: 1 }} // Pass the ID directly in the state
                >
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>{dashboardData.totalDomainByEgniol}</h3>
                      <p>Total Domain</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag" />
                    </div>
                  </div>
                </Link>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                <Link
                  to="/client-management"
                  state={{ id: 2 }} // Pass the ID directly in the state
                >
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>{dashboardData.totalRunningDomainByEgniol}</h3>
                      <p>Domain Live</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-6">
                {/* small box */}
                <Link
                  to="/client-management"
                  state={{ id: 3 }} // Pass the ID directly in the state
                >
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>{dashboardData.totalExpiredDomainByEgniol}</h3>
                      <p>Domain Expired</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-6">
                {/* small box */}
                <Link
                  to="/client-management"
                  state={{ id: 1 }} // Pass the ID directly in the state
                >
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>{dashboardData.totalHostingByEgniol}</h3>
                      <p>Total Hosting</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-6">
                {/* small box */}
                <Link
                  to="/client-management"
                  state={{ id: 2 }} // Pass the ID directly in the state
                >
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>{dashboardData.totalRunningHostingByEgniol}</h3>
                      <p>Hosting Live</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-6">
                {/* small box */}
                <Link
                  to="/client-management"
                  state={{ id: 6 }} // Pass the ID directly in the state
                >
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>{dashboardData.totalExpiredHostingByEgniol}</h3>
                      <p>Hosting Expired</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
